import { useAccount, useBalance, useNetwork } from 'wagmi'
import BigNumber from 'bignumber.js'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useToast } from 'state/hooks'
import { fetchSalesUserDataAsync, fetchProjectsDataAsync } from 'state/idos'
import { multicallv2 } from 'utils/multicall'
import IFAllocationSale from 'config/abi/IFAllocationSale.json'
import useWeb3 from 'hooks/useWeb3'

import { useIFASaleContract, useIFFixedSaleContract, useIFTieredSaleContract } from './useContract'
import useRefresh from './useRefresh'
import { fetchMerkleProof, fetchMerkleProofV2 } from './useWhitelistedPrivateSale'
import { CompanyContext, Brands } from 'contexts/CompanyContext'
import getCMSApiUrl from 'utils/getCMSApiUrl'
import { isAddress } from 'utils'

export const useWithdrawV2 = (
  address: string,
  salePrice: number,
  whitelistedUrl?: string,
  userAllocation?: string,
  saleId?: string,
  isVested?: boolean,
) => {
  const { address: account } = useAccount()
  const IFASaleContract = useIFASaleContract(address)
  const IFFixedSaleContract = useIFFixedSaleContract(address)
  const web3 = useWeb3()

  const [isLoading, setIsLoading] = useState(false)
  const { toastErrorV2 } = useToast()
  const dispatch = useDispatch()
  const balance = useBalance({
    address: account,
  })
  const handleWithdraw = useCallback(
    async (onSuccess?: any, onFailure?: any) => {
      try {
        setIsLoading(true)
        if (balance?.data?.value <= 0) {
          throw new Error('No gas fees in wallet, please obtain some gas and refresh')
        }
        let tx
        if (salePrice > 0) {
          const gasPrice = await web3.eth.getGasPrice()
          const gasAmount = await IFASaleContract.methods.withdraw().estimateGas({ from: account })

          tx = await IFASaleContract.methods.withdraw().send({ from: account, gasPrice: gasPrice, gasLimit: gasAmount })
        } else {
          let merkleProof
          if (userAllocation && saleId) {
            merkleProof = await fetchMerkleProofV2(account, saleId)
            const gasPrice = await web3.eth.getGasPrice()
            if (isVested) {
              const gasAmount = await IFFixedSaleContract.methods
                .withdrawGiveawayVested(merkleProof, userAllocation)
                .estimateGas({ from: account })

              tx = await IFFixedSaleContract.methods
                .withdrawGiveawayVested(merkleProof, userAllocation)
                .send({ from: account, gasPrice: gasPrice, gasLimit: gasAmount })
            } else {
              const gasAmount = await IFFixedSaleContract.methods
                .withdrawGiveaway(merkleProof, userAllocation)
                .estimateGas({ from: account })

              tx = await IFFixedSaleContract.methods
                .withdrawGiveaway(merkleProof, userAllocation)
                .send({ from: account, gasPrice: gasPrice, gasLimit: gasAmount })
            }
          } else if (whitelistedUrl) {
            merkleProof = await fetchMerkleProof(account, whitelistedUrl)
            const gasPrice = await web3.eth.getGasPrice()
            const gasAmount = await IFASaleContract.methods.withdrawGiveaway(merkleProof).estimateGas({ from: account })

            tx = await IFASaleContract.methods
              .withdrawGiveaway(merkleProof)
              .send({ from: account, gasPrice: gasPrice, gasLimit: gasAmount })
          } else {
            throw Error('Oops! We are unable to get the merkle proof, please try again in a few minutes!')
          }
        }
        dispatch(fetchSalesUserDataAsync(account))
        setIsLoading(false)
        if (onSuccess) onSuccess()
        return tx.transactionHash
      } catch (err: any) {
        setIsLoading(false)
        console.error(err)
        toastErrorV2(err.message)
        if (onFailure) onFailure()
        return null
      }
    },
    [account, dispatch, salePrice, whitelistedUrl, IFASaleContract.methods, toastErrorV2],
  )

  return { onWithdraw: handleWithdraw, isLoading }
}

export const useOptin = ({ saleAddress }: { saleAddress: string }) => {
  const { address: account } = useAccount()
  const { chain } = useNetwork()
  const chainId = chain?.id
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { toastErrorV2 } = useToast()
  const IFASaleContract = useIFFixedSaleContract(saleAddress)

  const handleOptIn = useCallback(
    async (onSuccess?: any) => {
      try {
        setIsLoading(true)
        const tx = await IFASaleContract.methods.optInBuyback().send({ from: account })
        dispatch(fetchSalesUserDataAsync(account))
        setIsLoading(false)
        if (onSuccess) onSuccess(tx.transactionHash)
        return tx.transactionHash
      } catch (err: any) {
        setIsLoading(false)
        console.error(err)
        toastErrorV2(err.message)
        return null
      }
    },
    [saleAddress, chainId, account, IFASaleContract.methods, dispatch, toastErrorV2],
  )

  return { onOptIn: handleOptIn, isLoading }
}

export const useGetHasOptedIn = (saleAddress: string) => {
  const { address: account } = useAccount()
  const { fastRefresh } = useRefresh()
  const [value, setValue] = useState(false)
  const IFASaleContract = useIFFixedSaleContract(saleAddress)

  useEffect(() => {
    const getHasOptedIn = async () => {
      try {
        const tx = await IFASaleContract.methods.hasOptInBuyback(account)
        const res = await tx.call()
        setValue(res)
      } catch (err) {
        console.error(err)
      }
      return null
    }

    if (account) {
      getHasOptedIn()
    }
  }, [fastRefresh])

  return value
}

export const useGetAllocation = (saleId: string, isPrivate: boolean, isSaleBriefCard?: boolean) => {
  const { address: account } = useAccount()
  const { isJustSale } = useContext(CompanyContext)
  const [value, setValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getUserAllocation = async () => {
      try {
        setIsLoading(true)
        const URL = process.env.REACT_APP_BACKEND_URL
        const ENDPOINT = `${getCMSApiUrl()}/api/backend-service/projects-v2/allocation?account=${account}&sale_id=${saleId}`
        const response = await fetch(`${ENDPOINT}`, {
          headers: [['Cache-Control', 'no-cache']],
        })
        const responseData = await response.json()
        setIsLoading(false)
        setValue(responseData.data)
      } catch (err) {
        setIsLoading(false)
        console.error(err)
      }
      return null
    }

    if (account) {
      // No need for node sale
      if (isJustSale && (!isPrivate || (isPrivate && isSaleBriefCard))) {
        setIsLoading(false)
        setValue('0')
      } else {
        getUserAllocation()
      }
    }
  }, [isJustSale, isPrivate, isSaleBriefCard, saleId])

  return { allo: value.toString(), isLoading }
}

export const useGetWhitelistSales = (saleIds: string) => {
  const { address: account } = useAccount()
  const [value, setValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getWhitelistSales = async (saleIds) => {
      try {
        setIsLoading(true)
        const URL = process.env.REACT_APP_BACKEND_URL
        const ENDPOINT = `${getCMSApiUrl()}/api/backend-service/projects-v2/whitelist?account=${account}&sale_ids=${saleIds}`

        const response = await fetch(`${ENDPOINT}`, {
          headers: [['Cache-Control', 'no-cache']],
        })
        const responseData = await response.json()
        setIsLoading(false)
        setValue(responseData.data)
      } catch (err) {
        setIsLoading(false)
        console.error(err)
      }
      return null
    }
    if (account && saleIds?.toString()?.length > 0) {
      getWhitelistSales(saleIds)
    }
  }, [saleIds, account])

  return { saleIds: value?.length > 0 ? value : [], isLoading }
}

export const useGetClaimableNumber = (saleAddress: string) => {
  const { address: account } = useAccount()
  const { fastRefresh } = useRefresh()
  const [value, setValue] = useState(0)
  const IFASaleContract = useIFFixedSaleContract(saleAddress)

  useEffect(() => {
    const getValue = async () => {
      try {
        const tx = await IFASaleContract.methods.buybackClaimableNumber()
        const res = await tx.call()
        setValue(res)
      } catch (err) {
        console.error(err)
      }
      return null
    }

    if (account) {
      getValue()
    }
  }, [fastRefresh])

  return value
}

export const usePurchaseV2 = ({
  saleAddress,
  whitelistedUrl,
  userAllocation,
  saleId,
  isPrivate,
  tierId,
}: {
  saleAddress: string
  whitelistedUrl?: string
  userAllocation?: string
  saleId?: string
  isPrivate?: boolean
  tierId?: string
}) => {
  const { address: account } = useAccount()
  const { chain } = useNetwork()
  const chainId = chain?.id
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const { brand, isJustSale, isNodeSale } = useContext(CompanyContext)

  const { toastErrorV2 } = useToast()
  const IFASaleContract = useIFASaleContract(saleAddress)
  const IFFixedSaleContract = useIFFixedSaleContract(saleAddress)
  const IFTieredSaleContract = useIFTieredSaleContract(saleAddress)
  const balance = useBalance({
    address: account,
  })

  const handlePurchase = useCallback(
    async (paymentAmount: string, promoCode: string, onSuccess?: any, onShallowSuccess?: any) => {
      try {
        setIsLoading(true)
        if (balance?.data?.value <= 0) {
          throw new Error('No gas fees in wallet, please obtain some gas and refresh')
        }
        const calls = [
          {
            address: saleAddress,
            name: 'whitelistRootHash',
          },
        ]
        // const [whitelistRootHash] = await multicallv2(IFAllocationSale, calls, chainId)

        // if (new BigNumber(whitelistRootHash).isZero()) {
        //   const gasPrice = await web3.eth.getGasPrice()
        //   const gasAmount = await IFASaleContract.methods.purchase(paymentAmount).estimateGas({ from: account })
        //   const tx = await IFASaleContract.methods
        //     .purchase(paymentAmount)
        //     .send({ from: account, gasPrice: gasPrice, gasLimit: gasAmount })
        //   dispatch(fetchSalesUserDataAsync(account))
        //   setIsLoading(false)
        //   if (onSuccess) onSuccess(tx.transactionHash)
        //   dispatch(fetchIdoUserDataAsync(account))
        //   dispatch(fetchProjectsDataAsync())
        //   return tx.transactionHash
        // }

        let merkleProof = []
        if (!isJustSale || isPrivate) {
          merkleProof = await fetchMerkleProofV2(account, saleId)
        }

        let tx

        if (isNodeSale) {
          if (promoCode?.length > 0) {
            const gasPrice = await web3.eth.getGasPrice()

            if (isAddress(promoCode)) {
              const gasAmount = await IFTieredSaleContract.methods
                .whitelistedPurchaseInTierWithWalletCode(
                  tierId,
                  paymentAmount,
                  merkleProof,
                  promoCode.toLowerCase(),
                  userAllocation,
                )
                .estimateGas({ from: account })

              tx = await IFTieredSaleContract.methods
                .whitelistedPurchaseInTierWithWalletCode(
                  tierId,
                  paymentAmount,
                  merkleProof,
                  promoCode.toLowerCase(),
                  userAllocation,
                )
                .send({
                  from: account,
                  gasPrice: gasPrice,
                  gasLimit: brand === Brands.RONIN ? ((gasAmount * 120) / 100).toFixed(0) : gasAmount,
                })
            } else {
              const gasAmount = await IFTieredSaleContract.methods
                .whitelistedPurchaseInTierWithCode(tierId, paymentAmount, merkleProof, promoCode, userAllocation)
                .estimateGas({ from: account })

              tx = await IFTieredSaleContract.methods
                .whitelistedPurchaseInTierWithCode(tierId, paymentAmount, merkleProof, promoCode, userAllocation)
                .send({
                  from: account,
                  gasPrice: gasPrice,
                  gasLimit: brand === Brands.RONIN ? ((gasAmount * 120) / 100).toFixed(0) : gasAmount,
                })
            }
          } else {
            const gasPrice = await web3.eth.getGasPrice()
            const gasAmount = await IFTieredSaleContract.methods
              .whitelistedPurchaseInTier(tierId, paymentAmount, merkleProof, userAllocation)
              .estimateGas({ from: account })

            tx = await IFTieredSaleContract.methods
              .whitelistedPurchaseInTier(tierId, paymentAmount, merkleProof, userAllocation)
              .send({
                from: account,
                gasPrice: gasPrice,
                gasLimit: brand === Brands.RONIN ? ((gasAmount * 120) / 100).toFixed(0) : gasAmount,
              })
          }
        } else {
          if (promoCode?.length > 0) {
            const gasPrice = await web3.eth.getGasPrice()
            const gasAmount = await IFFixedSaleContract.methods
              .whitelistedPurchaseWithCode(paymentAmount, merkleProof, userAllocation, promoCode)
              .estimateGas({ from: account })

            tx = await IFFixedSaleContract.methods
              .whitelistedPurchaseWithCode(paymentAmount, merkleProof, userAllocation, promoCode)
              .send({
                from: account,
                gasPrice: gasPrice,
                gasLimit: brand === Brands.RONIN ? ((gasAmount * 120) / 100).toFixed(0) : gasAmount,
              })
          } else {
            const gasPrice = await web3.eth.getGasPrice()
            const gasAmount = await IFFixedSaleContract.methods
              .whitelistedPurchase(paymentAmount, merkleProof, userAllocation)
              .estimateGas({ from: account })

            tx = await IFFixedSaleContract.methods
              .whitelistedPurchase(paymentAmount, merkleProof, userAllocation)
              .send({
                from: account,
                gasPrice: gasPrice,
                gasLimit: brand === Brands.RONIN ? ((gasAmount * 120) / 100).toFixed(0) : gasAmount,
              })
          }
        }

        if (onShallowSuccess) onShallowSuccess()
        dispatch(fetchSalesUserDataAsync(account))
        dispatch(fetchProjectsDataAsync())
        setIsLoading(false)
        if (onSuccess) onSuccess(tx.transactionHash)
        return tx.transactionHash
      } catch (err: any) {
        setIsLoading(false)
        dispatch(fetchProjectsDataAsync())
        console.error(err)
        toastErrorV2(`Your recent purchase attempt was unsuccessful`, err?.message?.message)
        return null
      }
    },
    [
      saleAddress,
      userAllocation,
      chainId,
      whitelistedUrl,
      account,
      IFASaleContract.methods,
      dispatch,
      toastErrorV2,
      isJustSale,
      isPrivate,
      tierId,
    ],
  )

  return { onPurchase: handlePurchase, isLoading }
}
//for a new Contract we don't have getMaxPayment method 26.07.24
export const useGetMaxPaymentInWeiV2 = (address: string, allocation?: string) => {
  const { address: account } = useAccount()
  const IFASaleContract = useIFASaleContract(address)

  const IFFixedSaleContract = useIFFixedSaleContract(address)

  const { fastRefresh } = useRefresh()
  const [value, setValue] = useState(new BigNumber(0))

  useEffect(() => {
    const getMaxPayment = async () => {
      try {
        const tx = await IFASaleContract.methods.getMaxPayment(account)
        const res = await tx.call()
        setValue(new BigNumber(res))
      } catch (err) {
        console.error(err)
      }
      return null
    }

    const getMaxFixedPayment = async () => {
      try {
        const tx = await IFFixedSaleContract.methods.getMaxPayment(account, allocation)
        const res = await tx.call()
        console.log('tx result', tx, res)
        setValue(new BigNumber(res))
      } catch (err) {
        console.error('errror getMaxFixedPayment', err)
      }
      return null
    }

    if (account) {
      if (!allocation || allocation === '0') {
        getMaxPayment()
      } else {
        getMaxFixedPayment()
      }
    }
  }, [account, address, fastRefresh, IFASaleContract.methods, IFFixedSaleContract.methods])

  return value
}
